.status-document-in-progress {
  background-color: #898989;
}

.status-reclamation-wrongly-created {
  background-color: #e91f1f;
}

.status-service-sent-to-repair {
  background-color: #3ac9c9;
}

.status-service-waiting-for-repair {
  background-color: #39b5df;
}

.status-service-repair-in-progress {
  background-color: #3399ff;
}

.status-service-waiting-replacement-part {
  background-color: #3183ff;
}

.status-service-done {
  background-color: #83c23d;
}

.status-reclamation-waiting {
  background-color: #ff9933;
}

.status-reclamation-in-progress {
  background-color: #ff6600;
}

.status-reclamation-done {
  background-color: #009a46;
}